
import { defineComponent, ref, onMounted } from "vue";
import ClipboardJS from "clipboard";

export default defineComponent({
  name: "ShareAndEarnModal",
  components: {},
  setup() {
    const copyButtonRef = ref<null | HTMLElement>(null);
    const inputRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      const clipboard = new ClipboardJS(copyButtonRef.value as Element);

      clipboard.on("success", function (e) {
        const buttonCaption = copyButtonRef.value?.innerHTML;
        //Add bgcolor
        inputRef.value?.classList.add("bg-success");
        inputRef.value?.classList.add("text-inverse-success");

        if (copyButtonRef.value) {
          copyButtonRef.value.innerHTML = "Copied!";
        }

        setTimeout(function () {
          if (copyButtonRef.value && buttonCaption) {
            copyButtonRef.value.innerHTML = buttonCaption;
          }

          // Remove bgcolor
          inputRef.value?.classList.remove("bg-success");
          inputRef.value?.classList.remove("text-inverse-success");
        }, 3000); // 3seconds

        e.clearSelection();
      });
    });

    return {
      copyButtonRef,
      inputRef,
    };
  },
});
